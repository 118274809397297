@import '../../styles/bootstrap-imports.scss';

@mixin rotation($rotation) {
  transform: rotate($rotation);
  -ms-transform: rotate($rotation);
  -moz-transform: rotate($rotation);
  -webkit-transform: rotate($rotation);
}


.topImg {
  @include rotation(-2deg);
  width: 90%;
  margin-top: -40px;

  @include media-breakpoint-up(lg) {
    width: 100%;
    margin-top: -80px;
  }
}

.mission {
  font-size: 34px;
  line-height: 43px;

  @include media-breakpoint-down(md) {
    font-size: 26px;
    line-height: 32px;
  }

  &Sub {
    font-size: 16px;
    line-height: 24px;

    @include media-breakpoint-down(md) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.h3 {
  font-size: 34px;
  line-height: 43px;

  @include media-breakpoint-down(md) {
    font-size: 26px;
    line-height: 32px;
  }

  &Sub {
    font-size: 16px;
    line-height: 24px;

    @include media-breakpoint-down(md) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.loginDialog {
  width: 100%;
  max-width: 498px;
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 60px;
  padding: 20px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

}
